import * as logger from "@/tools/logger.js";
import * as statusMapper from "@/service/error_request_mapper.js";

const _FILE_PATH = "service/dictionary/refund_type_service.js";
const _M_GET_ALL = "getAllRefundTypes";
const _M_GET_BY_ID = "getRefundTypeById";

/** Service d'accès à l'API des natures de remboursement */
export default class RefundTypeService {
  constructor(apis) {
    this.apis = apis;
    this.api = this.apis.getRefundTypesApi();
  }

  /////////////////////////////// GET /////////////////////////////////////////
  /**
   * Récupère la liste des natures de remboursement et les tri par ordre alphabétique
   */
  async getAllRefundTypes() {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_ALL,
      "Récupération de toutes les natures de remboursement."
    );

    return this.api
      .getAll()
      .then((data) => {
        data.sort(function(a, b) {
          return a.label.localeCompare(b.label);
        });
        return data;
      })
      .catch((error) => {
        let converter = new statusMapper.StatusConverter();
        converter.convert(error);
      });
  }

  /**
   * Récupère une nature de remboursement par son id
   */
  async getRefundTypeById(id) {
    logger.debugFull(
      _FILE_PATH,
      _M_GET_BY_ID,
      "Récupération de la nature de remboursement avec l'id : " + id
    );

    return this.api.getById(id).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.convert(error);
    });
  }

  /////////////////////////////// CREATE /////////////////////////////////////////

  async create(type) {
    return this.api.create(type).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(type.id);
      converter.add409AlreadyExists(type.name);
      converter.convert(error);
    });
  }

  /////////////////////////////// UPDATE /////////////////////////////////////////

  async update(type) {
    return this.api.update(type).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add404(type.id);
      converter.convert(error);
    });
  }

  /////////////////////////////// DELETE /////////////////////////////////////////

  async delete(typeId) {
    return this.api.delete(typeId).catch((error) => {
      let converter = new statusMapper.StatusConverter();
      converter.add409Integrity(typeId);
      converter.convert(error);
    });
  }
}
